import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { MdCheck } from 'react-icons/md';
import Flag from 'react-world-flags';
import uk from './assests/uk.jpg';
import poland from './assests/poland.jpg';
import americaImage from './assests/america.jpg';
import canadaImage from './assests/canada.jpg';
import malta from './assests/malta.jpg';
import portugal from './assests/portugal.jpg';
import finland from './assests/finland.jpg';
import germany from './assests/germany.jpg';
import luxembourg from './assests/luxembourg.jpg';
import italy from './assests/italy.jpg';
import australia from './assests/australia.jpg';
import netherlands from './assests/netharlands.jpg';

const countryData = {
    uk: {
      image: uk,
      details: 'United Kingdom is known for its rich history and culture. Immigrating to the UK offers numerous advantages, including access to high-quality healthcare through the NHS and world-class education at prestigious institutions like Oxford and Cambridge. The UKs multicultural society provides a vibrant cultural experience, complemented by rich historical heritage and diverse communities. With robust economic opportunities across various sectors and a supportive environment for businesses, the UK serves as a global financial and political hub. The country also boasts easy travel connections across Europe, high living standards, and a strong focus on work-life balance. Additionally, residents benefit from social welfare programs, safety, and the opportunity to enhance English language skills in a dynamic, innovative environment.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'GB' 
    },
    poland: {
      image: poland,
      details: 'Poland offers a beautiful landscape and vibrant culture.Immigrating to Poland presents a range of benefits, including a high standard of living and a relatively low cost of living compared to other European countries. The country boasts a strong healthcare system and a solid education system with affordable tuition fees. Polands rich cultural heritage and vibrant history offer a unique living experience, complemented by a growing economy with opportunities in various sectors like technology, manufacturing, and services. The countrys strategic location in Central Europe provides easy access to travel across Europe. Poland is known for its friendly and welcoming people, picturesque landscapes, and dynamic cities that blend historical charm with modern amenities, making it an attractive destination for those seeking new opportunities and a high quality of life.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'PL'
    },
    australia: {
      image: australia,
      details: 'Australia is famous for its natural wonders and laid-back lifestyle.Immigrating to Australia offers numerous advantages, including a high quality of life and access to a robust healthcare system through Medicare. The country boasts world-renowned educational institutions and a diverse, multicultural society that embraces people from all backgrounds. Australia’s strong economy presents abundant opportunities in various sectors such as technology, healthcare, and finance. The country is known for its stunning natural landscapes, including beaches, national parks, and a generally pleasant climate. Its strategic location in the Asia-Pacific region also provides convenient access to other global markets. With a focus on work-life balance, a welcoming environment, and vibrant cities like Sydney and Melbourne, Australia offers a dynamic and rewarding living experience.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'AU'
    },
    canada: {
      image: canadaImage,
      details: 'Canada is known for its stunning natural landscapes and diverse culture.Immigrating to Canada offers a host of benefits, including a high standard of living and access to a comprehensive healthcare system through Medicare. The country is renowned for its world-class education system and diverse, inclusive society that values multiculturalism. Canada’s strong economy provides ample opportunities in various fields such as technology, natural resources, and finance. With stunning natural landscapes, from mountains and forests to lakes and coastlines, and a generally welcoming climate, Canada offers an exceptional quality of life. The country also features vibrant cities like Toronto, Vancouver, and Montreal, each with unique cultural and recreational offerings. Additionally, Canada’s emphasis on work-life balance and social welfare programs makes it an attractive destination for those seeking new opportunities and a supportive living environment.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'CA'
    },
    usa: {
      image: americaImage,
      details: 'The United States is renowned for its diverse culture and economic opportunities.Immigrating to the United States provides numerous advantages, including access to a world-leading healthcare system and top-tier educational institutions like Harvard and MIT. The U.S. offers a diverse and dynamic cultural environment with a rich history of innovation and economic opportunity across various sectors, including technology, finance, and entertainment. The country’s large and diverse landscape provides a wide range of living environments, from bustling cities to serene rural areas. With its strong economy and entrepreneurial spirit, the U.S. is a hub for business and career growth. Additionally, the country’s strategic location and global influence facilitate easy international travel and connections. The vibrant lifestyle, abundant amenities, and focus on personal freedom and opportunity make the U.S. an attractive destination for immigrants seeking a high quality of life and diverse experiences.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'US'
    },
    malta: {
      image: malta,
      details: 'Malta is known for its rich history and beautiful Mediterranean landscapes.Immigrating to Malta offers several appealing benefits, including a high quality of life and a Mediterranean climate with mild winters and warm summers. The country provides access to a robust healthcare system and a well-regarded education system with affordable tuition fees. Malta’s rich history and cultural heritage, combined with its strategic location in the heart of the Mediterranean, offer a unique and vibrant living experience. The island is known for its friendly community, picturesque landscapes, and historical sites, making it an attractive destination for those seeking both relaxation and adventure. Additionally, Malta’s growing economy, particularly in sectors like tourism, finance, and IT, provides ample opportunities for professionals. The countrys English-speaking environment and favorable business climate further enhance its appeal as a place to live and work.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'MT'
    },
    portugal: {
      image: portugal,
      details: 'Portugal offers a warm climate and rich cultural heritage. Immigrating to Portugal offers a range of advantages, including a high quality of life and a favorable climate with mild winters and warm, sunny summers. The country provides access to a well-regarded healthcare system and an affordable education system, making it an attractive option for families. Portugal is known for its rich cultural heritage, beautiful landscapes, and historic cities such as Lisbon and Porto, which blend traditional charm with modern amenities. The countrys relatively low cost of living, vibrant community, and relaxed lifestyle contribute to a high standard of living. Additionally, Portugals growing economy, particularly in sectors like technology and tourism, offers various professional opportunities. Its strategic location on the Atlantic coast provides easy access to other European countries, enhancing both travel and business prospects.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'PT'
    },
    finland: {
      image: finland,
      details: 'Finland offers stunning natural beauty and a high standard of living. Immigrating to Finland offers several compelling benefits. The country boasts a high standard of living, characterized by excellent healthcare, world-class education, and a robust social welfare system. Finland is known for its strong emphasis on work-life balance, with generous parental leave policies and a culture that values family and personal time. The safety and cleanliness of Finnish cities contribute to a high quality of life, while its progressive environmental policies ensure a sustainable future. Additionally, Finlands commitment to innovation and technology provides numerous opportunities for professional growth, making it an attractive destination for those seeking both personal and career advancement.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'FI'
    },
    luxembourg: {
      image: luxembourg,
      details: 'Luxembourg is known for its rich cultural heritage and economic stability.Immigrating to Luxembourg comes with a range of advantages. Known for its high standard of living, Luxembourg offers excellent healthcare, a strong social security system, and a well-regarded education system. The country’s strategic location in Europe provides easy access to neighboring countries, making it ideal for travel and international business. Luxembourg also boasts a strong economy with a thriving financial sector, offering numerous professional opportunities. Its multicultural environment and high level of safety contribute to a high quality of life. Additionally, Luxembourg’s rich history and vibrant cultural scene add to its appeal as a place to live and work.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'LU'
    },
    italy: {
      image: italy,
      details: 'Italy is renowned for its art, history, and cuisine.Immigrating to Italy provides a host of appealing benefits. Known for its rich cultural heritage and stunning landscapes, Italy offers a high quality of life with a focus on family values and a relaxed lifestyle. The country boasts a world-renowned cuisine, vibrant arts scene, and historical landmarks that enrich daily life. Italy’s healthcare system is comprehensive and widely accessible, while its education system offers quality options for families. Additionally, Italys strategic location in Southern Europe allows for easy travel across the continent. The strong sense of community and warm hospitality make Italy a welcoming place for newcomers.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'IT'
    },
    netherlands: {
      image: netherlands,
      details: 'The Netherlands is known for its picturesque canals and vibrant culture.Immigrating to the Netherlands offers numerous benefits. The country is known for its high standard of living, excellent healthcare, and strong social services. The Dutch education system is highly regarded, with a variety of international schools and higher education institutions. The Netherlands is also famous for its progressive policies, including a strong focus on work-life balance, extensive parental leave, and a welcoming attitude towards diverse cultures. The country’s efficient public transportation system and bike-friendly cities make commuting and living convenient. Additionally, the Netherlands vibrant cultural scene, picturesque landscapes, and emphasis on sustainability enhance the overall quality of life for its residents.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'NL'
    },
    germany: {
      image: germany,
      details: 'Germany is renowned for its engineering and historical landmarks. Immigrating to Germany offers a range of advantages. The country is known for its strong economy, providing numerous career opportunities across various industries, particularly in engineering, technology, and manufacturing. Germany boasts a high standard of living with excellent healthcare, a comprehensive social welfare system, and a high-quality education system. The countrys rich cultural history, vibrant cities, and beautiful landscapes enhance its appeal. Additionally, Germany is known for its efficient public transportation system, which makes getting around easy. The emphasis on environmental sustainability and a progressive approach to social policies contribute to a well-rounded and attractive living experience.',
      visaTypes: ['Permanent Residency', 'Work Visa', 'Business Visa', 'Student Visa', 'Tourist Visa'],
      flagCode: 'DE'
    }
};

const CountryPage = () => {
  const { country } = useParams();
  const countryInfo = countryData[country.toLowerCase()] || {}; 

  if (!countryInfo.image) {
    return <h1>Country Not Found</h1>;
  }

  return (
    <div className="country-page">
      <div className="d-flex flex-column flex-md-row">
        <img 
          src={countryInfo.image} 
          alt={`${country}`} 
          className="country-image" 
          style={{ borderRadius: "7px" }} 
        />
        <div className="country-details">
          <div className='d-flex align-items-center'>
            <h1 className='m-0 p-0'>{country.charAt(0).toUpperCase() + country.slice(1)}</h1>
            <Flag
              code={countryInfo.flagCode}
              style={{ width: '40px', height: '40px', marginLeft: '0.5rem' }}
              className="flag-icon-2"
            />
          </div>
          <p>{countryInfo.details}</p>
          <h5>Visa Types</h5>
        
            {countryInfo.visaTypes.map((visa, index) => (
              <p key={index} style={{ lineHeight: "0.7", display: 'flex', alignItems: 'center' }}>
                
                {visa}
                <MdCheck style={{ color: 'green', marginLeft: '8px' }} />
              </p>
            ))}
          
          <Link to="/contact" className="submit-button" style={{ textDecoration: 'none' }}>
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CountryPage;
