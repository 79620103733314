import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import americaImage from "./assests/america.jpg";
import canadaImage from "./assests/canada.jpg";
import malta from "./assests/malta.jpg";
import portugal from "./assests/portugal.jpg";
import poland from "./assests/poland.jpg";
import finland from "./assests/finland.jpg";
import germany from "./assests/germany.jpg";
import luxembourg from "./assests/luxembourg.jpg";
import italy from "./assests/italy.jpg";
import australia from "./assests/australia.jpg";
import netherlands from "./assests/netharlands.jpg";
import ControlledCarousel from "./components/carousal";
import Loader from "./components/loader";
import Contact from "./contact";
import AboutUs from "./aboutus";
import CountryPage from "./country";
const Header = lazy(() => import("./components/header"));
const Footer = lazy(() => import("./components/footer"));
const Hero = lazy(() => import("./components/Hero"));
const Photocard = lazy(() => import("./components/Photocard"));

const WhatsAppButton = lazy(() => import("./components/whatsapp"));
const ArticleComponent = lazy(() => import("./components/article"));
const AddressSection = lazy(() => import("./components/address"));
const ImmigrationConsultantContainer = lazy(() =>
  import("./components/request")
);

function App() {
  const visaData = [
    {
      image: americaImage,
      country: "United States",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/usa",
    },
    {
      image: canadaImage,
      country: "Canada",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/canada",
    },
    {
      image: malta,
      country: "Malta",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/malta",
    },
    {
      image: portugal,
      country: "Portugal",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/portugal",
    },
    {
      image: poland,
      country: "Poland",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/poland",
    },
    {
      image: finland,
      country: "Finland",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/finland",
    },
    {
      image: luxembourg,
      country: "Luxembourg",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/luxembourg",
    },
    {
      image: italy,
      country: "Italy",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/italy",
    },
    {
      image: netherlands,
      country: "Netherlands",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/netherlands",
    },
    {
      image: germany,
      country: "Germany",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/germany",
    },
    {
      image: australia,
      country: "Australia",
      services: [
        "Permanent Residency",
        "Work Visa",
        "Business Visa",
        "Student Visa",
        "Tourist Visa",
      ],
      path: "/australia",
    },
  ];

  return (
    <Router>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <WhatsAppButton
            phoneNumber="+971524005457"
            message="Hello! I would like more information."
          />
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Photocard />
                  <ArticleComponent />
                  <ControlledCarousel visaData={visaData} />
                  <ImmigrationConsultantContainer />
                  <AddressSection />
                </>
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/:country" element={<CountryPage />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
