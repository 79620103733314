import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import VisaCardComponent from "./cards";

function ControlledCarousel({ visaData }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {visaData.map((visa, idx) => (
        <div key={idx}>
          <VisaCardComponent
            image={visa.image}
            country={visa.country}
            services={visa.services}
            isImageLeft={idx % 2 === 0}
            pathis={visa.path}
          />
        </div>
      ))}
    </>
  );
}

export default ControlledCarousel;
