import React from "react";
import Flag from "react-world-flags";

const VisaCardComponent = ({
  image,
  country,
  services,
  isImageLeft,
  pathis,
}) => {
  const getFlagCode = (country) => {
    switch (country) {
      case "United States":
      case "USA":
        return "US";
      case "Canada":
        return "CA";
      case "Malta":
        return "MT";
      case "Portugal":
        return "PT";
      case "Poland":
        return "PL";
      case "Finland":
        return "FI";
      case "Luxembourg":
        return "LU";
      case "Italy":
        return "IT";
      case "Netherlands":
        return "NL";
      case "Germany":
        return "DE";
      case "Australia":
        return "AU";
      default:
        return "";
    }
  };

  return (
    <div className="visa-card-container  m-2" style={{ maxHeight: "700px" }}>
      <div className={`row align-items-center  justify-content-center`}>
        <div className="col-md-6 p-0">
          <img src={image} alt={country} className="visa-image" />
        </div>
        <div className="col-md-6">
          <div className="visa-content">
            <h3 className="country-name">
              {country}
              <Flag
                code={getFlagCode(country)}
                style={{ width: "40px", height: "40px", marginLeft: "0.5rem" }}
                className="flag-icon"
              />
            </h3>
            <ul className="list-unstyled visa-services">
              {services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
            <a className="know-more-button" href={pathis}>
              Know More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisaCardComponent;
